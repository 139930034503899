import React, { useEffect, useState } from "react";
import { NavLinkProps } from ".";
import { ChevronIcon } from "../../Icons";
import NavBasic from "./NavBasic";
import NavMobile from "./NavMobile";
export default function Nav({ navsItems }: { navsItems: NavLinkProps[] }) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > (window.innerWidth > 600 ? 200 : 0)) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav id="#nav" className="fixed w-full top-0  z-30">
      <div
        className={`flex md:hidden w-full  ${
          isScrolled ? " bg-[#111827]" : "  bg-transparent"
        }  transition-[background,height] duration-500  `}
      >
        <NavMobile navItems={navsItems} isScrolled={isScrolled} />
      </div>
      <div
        className={`hidden md:flex flex-col  ${
          isScrolled ? " bg-[#111827]" : " bg-transparent"
        }  py-5 transition-[background,height] duration-200 sm:duration-500  `}
      >
        <NavBasic navItems={navsItems} isScrolled={isScrolled} />
      </div>
      <button
        type="button"
        className="fixed flex sm:hidden bottom-4 right-4 bg-[#09BFE2] text-black p-2 rounded-full w-14 h-14 justify-center items-center "
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <ChevronIcon
          svg="w-4 h-4"
          className="w-2 h-3 fill-white  origin-center rotate-180"
        />
      </button>
    </nav>
  );
}
