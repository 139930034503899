import { graphql, StaticQuery } from "gatsby";
import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import useLanguage from "../../Hooks/useLang";
// import useLanguage from "../../Hooks/useLang";
// export default function HeadWrapper(props: {
//   children?: React.ReactNode;
//   type: string;
//   keywords?: string[];
// }) {
//   // const { isArabic } = useLanguage();
//   return (
//     <LanguageProvider>
//       <SEO {...props} />
//     </LanguageProvider>
//   );
// }

export default function SEO({
  children,
  type,
  keywords,
}: {
  children?: React.ReactNode;
  type: string;
  keywords?: string[];
}) {
  const { isArabic } = useLanguage();
  const helmetRef = useRef<any>();

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          sanitySeo {
            title {
              ar
              en
            }
            description {
              ar
              en
            }
            keywords
            pagesInfo {
              aboutTitle {
                description {
                  ar
                  en
                }
                title {
                  ar
                  en
                }
              }
              blogTitle {
                description {
                  ar
                  en
                }
                title {
                  ar
                  en
                }
              }
              newsTitle {
                description {
                  ar
                  en
                }
                title {
                  ar
                  en
                }
              }
              contactTitle {
                description {
                  ar
                  en
                }
                title {
                  ar
                  en
                }
              }
              faqTitle {
                description {
                  ar
                  en
                }
                title {
                  ar
                  en
                }
              }
              fundraiserTitle {
                description {
                  ar
                  en
                }
                title {
                  ar
                  en
                }
              }
              homeTitle {
                description {
                  ar
                  en
                }
                title {
                  ar
                  en
                }
              }
              investorTitle {
                description {
                  ar
                  en
                }
                title {
                  ar
                  en
                }
              }
              policyTitle {
                description {
                  ar
                  en
                }
                title {
                  ar
                  en
                }
              }
              policyTitle {
                description {
                  ar
                  en
                }
                title {
                  ar
                  en
                }
              }
              privacyAndPolicyTitle {
                description {
                  ar
                  en
                }
                title {
                  ar
                  en
                }
              }
            }
            logoLarge {
              asset {
                url
              }
            }
            logoSmall {
              asset {
                url
              }
            }
          }
        }
      `}
      render={({ sanitySeo }) => {
        return (
          <Helmet
            ref={helmetRef}
            htmlAttributes={{
              lang: "ar",
              dir: isArabic ? "rtl" : "ltr",
            }}
            title={sanitySeo?.title?.[isArabic ? "ar" : "en"]}
            titleTemplate={
              sanitySeo?.title?.[isArabic ? "ar" : "en"]
                ? `%s | ${sanitySeo?.title?.[isArabic ? "ar" : "en"]}`
                : undefined
            }
            meta={[
              {
                name: `description`,
                content: sanitySeo?.description?.[isArabic ? "ar" : "en"],
              },
              {
                name: `keywords`,
                content: [keywords || sanitySeo?.keywords]?.join(", "),
              },
            ]}
          >
            <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "name": "دينــار",
                  "description": "منصة التمويل بالصكوك والاستثمار بها | تمويل يتماهى مع تطلعاتك | مرخصة من هيئة السوق المالية.",
                  "url": "https://www.dinar.sa/",
                  "logo": "https://app.dinar.sa/static/dinarLogo.svg",
                  "sameAs": [
                    "https://www.instagram.com/getdinar",
                    "https://twitter.com/getDinar",
                    "https://www.facebook.com/getDinar",
                    "https://www.linkedin.com/company/dinar-investments-%D8%AF%D9%8A%D9%86%D8%A7%D8%B1-%D9%84%D9%84%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1",
                  ]
                }
                `}
            </script>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-E9LC2PBC5K"
            ></script>
            <script
              async
              type="text/javascript"
              src={
                process.env.GATSBY_SANITY_DATASET === "staging"
                  ? "/js/relicstg.js"
                  : "/js/relicprod.js"
              }
            />
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments)};
                gtag('js', new Date());
                gtag('config', 'GT-E9LC2PBC5K');          
                gtag('config', 'AW-11003969364');
              
              `}
            </script>

            {/* Google Tag Manage */}
            <script>
              {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode .insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-MC5QBHP');
              `}
            </script>
            {/*  End Google Tag Manager */}

            {/* Linkedin Insight Tag  */}
            <script async type="text/javascript" src={"/js/linkedin_id.js"} />
            <script async type="text/javascript" src={"/js/linkedin_func.js"} />
            {/* <noscript>
              <img
                height="1"
                width="1"
                style="display:none;"
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=7064345&fmt=gif"
              />
            </noscript> */}

            {/*  Linkedin Insight Tag End */}

            {/* <script>
              {`
               function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-11003969364/QYYFCOLtir0YENT-jP8o', 'event_callback': callback }); return false; } 
                `}
            </script> */}
            {sanitySeo?.pagesInfo[type]?.title?.[isArabic ? "ar" : "en"] ? (
              <title>
                {sanitySeo?.pagesInfo[type]?.title?.[isArabic ? "ar" : "en"]}
              </title>
            ) : (
              <title>{sanitySeo?.title?.[isArabic ? "ar" : "en"]}</title>
            )}
            {sanitySeo?.pagesInfo[type]?.description?.[
              isArabic ? "ar" : "en"
            ] ? (
              <meta
                name="description"
                content={
                  sanitySeo?.pagesInfo[type]?.description?.[
                    isArabic ? "ar" : "en"
                  ]
                }
              />
            ) : (
              <meta
                name="description"
                content={sanitySeo?.description?.[isArabic ? "ar" : "en"]}
              />
            )}
            <link
              rel="icon"
              type="image/png"
              href={sanitySeo?.logoSmall?.asset?.url}
            />
            <link
              rel="shortcut icon"
              type="image/png"
              href={sanitySeo?.logoSmall?.asset?.url}
            />
            <link
              rel="apple-touch-icon"
              href={sanitySeo?.logoSmall?.asset?.url}
            ></link>
            <meta property="og:type" content="website" />
            <meta property="og:url" content={sanitySeo?.url} />
            <meta
              property="og:title"
              content={sanitySeo?.title?.[isArabic ? "ar" : "en"]}
            />
            <meta
              property="og:description"
              content={sanitySeo?.description?.[isArabic ? "ar" : "en"]}
            />
            <meta
              property="og:image"
              content={sanitySeo?.logoLarge?.asset?.url}
            />
            <meta
              name="twitter:card"
              content={sanitySeo?.logoLarge?.asset?.url}
            />
            <meta property="twitter:url" content={sanitySeo?.url} />
            <meta
              name="twitter:title"
              content={sanitySeo?.title?.[isArabic ? "ar" : "en"]}
            />
            <meta
              name="twitter:description"
              content={sanitySeo?.description?.[isArabic ? "ar" : "en"]}
            />
            <meta
              name="twitter:image"
              content={sanitySeo?.logoLarge?.asset?.url}
            />
            <meta itemProp="image" content={sanitySeo?.logoLarge?.asset?.url} />
            <meta
              name="google-site-verification"
              content="uVfZRiLU9S5r7ZnKcaKiBsu8nBva_GtBJwsw48ZhHEA"
            />
            {children}
          </Helmet>
        );
      }}
    />
  );
}
