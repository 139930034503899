import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useModal } from "../../../Hooks";
import useLanguage from "../../../Hooks/useLang";
import dinarLogo from "../../../assets/dinarLogoWhiteText.svg";
import linkedin from "../../../assets/icons/linkedin.svg";
import twitter from "../../../assets/icons/twitter.svg";
import { login, sign } from "../../../constants/dualLang";
import { PATH_LANDING } from "../../../paths";
import { LogoutIcon, MenuIcon, TimesIcon } from "../../Icons";
import LangSwitch from "../../LangSwitch";
import { NavLinkProps, NavMobileLink } from "../../NavButtons/NavLink";

const links = [
  {
    alt: "linkedin",
    src: linkedin,
    width: 30,
    height: 30,
    href: "https://www.linkedin.com/company/dinar-investments-دينار-للاستثمار",
  },
  {
    alt: "twitter",
    src: twitter,
    width: 30,
    height: 30,
    href: "https://twitter.com/getDinar",
  },
];

const NavMenuContainer = ({
  isOpen,
  setIsOpen,
  children,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
}) => {
  const [isShown, setIsSHown] = useState(false);
  const wrapperRef = useRef(null);

  const closeModal = useCallback(async () => {
    setTimeout(() => setIsSHown(false), 400);
  }, []);

  const openModal = useCallback(async () => {
    setTimeout(() => setIsSHown(true), 100);
  }, []);

  useEffect(() => {
    if (isOpen) {
      openModal();
    } else closeModal();
  }, [isOpen, closeModal, openModal]);

  if (!isOpen && !isShown) return <></>;
  return (
    <div
      className={`fixed duration-300 h-full toRight ${
        isShown && isOpen
          ? "bg-background-modal-transparent"
          : "transparent delay-50 "
      } flex items-center justify-start w-full h-full z-40 top-0 right-0 `}
    >
      <div
        ref={wrapperRef}
        className={` h-full duration-300 ${
          isShown && isOpen
            ? " translate-x-[0] opacity-100"
            : " translate-x-20 opacity-0"
        }`}
      >
        <div className="flex flex-col bg-[#1768AC] h-full w-[85vw]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default function NavMobile({
  navItems,
  isScrolled,
}: {
  navItems: NavLinkProps[];
  isScrolled: boolean;
}) {
  const { isOpen, setIsOpen } = useModal();
  const location = useLocation();
  const isArabic = useLanguage().isArabic;
  return (
    <div className=" flex w-full items-center relative">
      <NavMenuContainer
        isOpen={!!isOpen}
        setIsOpen={(bool: boolean) => setIsOpen(bool)}
      >
        <div className="flex flex-col gap-6 p-8 px-6 w-full h-full">
          <div className="flex flex-col gap-10 ">
            <div className="flex justify-between w-full">
              <div className="flex gap-5 items-center">
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className=" z-10 mx-2 p-2"
                >
                  <TimesIcon
                    svg=" w-[26px] h-[30px] cursor-pointer "
                    className=" fill-white"
                  />
                </button>
                <Link
                  onClick={() => setIsOpen(false)}
                  to={PATH_LANDING.home + '#top'}
                  className=" cursor-pointer max-w-[260px] w-[10vw] min-w-[130px] "
                >
                  <img
                    height={40}
                    width={100}
                    src={dinarLogo}
                    alt="dinar logo"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1  justify-between">
            <div className="flex flex-col  gap-6 mt-5 w-full justify-start">
              {navItems.map((item, index) => (
                <button
                  key={`nav__mobile__item__${index}`}
                  onClick={() => (item.multiple ? null : setIsOpen(false))}
                  className=""
                >
                  <NavMobileLink
                    close={() => setIsOpen(false)}
                    title={item.title}
                    href={item.href + '#top'}
                    icon={item.icon}
                    root={item.root}
                    selected={
                      item.multiple
                        ? !!item.multiple.find(
                            (el) => location.pathname === el.href
                          )
                        : location.pathname === item.href
                    }
                    multiple={item.multiple}
                  />
                </button>
              ))}
            </div>
            <div className="flex flex-col gap-4">
              <a target={"_blank"} href={`https://app.dinar.sa/`}>
                <button
                  type="button"
                  className={
                    isArabic
                      ? "flex items-center ml-auto"
                      : "flex items-center mr-auto"
                  }
                >
                  <div
                    className={` relative cursor-pointer flex items-center ${
                      isArabic ? "" : "flex-row-reverse"
                    }  justify-start py-1 gap-4  `}
                  >
                    <div
                      className={`${
                        isArabic ? "" : "rotate-180"
                      } flex relative w-[20px] transition-opacity 'svg:opacity-50'`}
                    >
                      <LogoutIcon
                        svg=" opacity-50 rotate-180"
                        className=" fill-white "
                      />
                    </div>
                    <p className="text-p text-white text-base">
                      {login[isArabic ? "ar" : "en"]}
                    </p>
                  </div>
                </button>
              </a>
              {/* <a
                target={"_blank"}
                href={`${process.env.GATSBY_APP_URL + "/sign"}`}
              >
                <button
                  type="button"
                  className={
                    isArabic
                      ? "flex items-center ml-auto"
                      : "flex items-center mr-auto"
                  }
                >
                  <div
                    className={` relative cursor-pointer flex items-center ${
                      isArabic ? "" : "flex-row-reverse"
                    }  justify-start py-1 gap-4  `}
                  >
                    <div
                      className={`${
                        isArabic ? "" : "rotate-180"
                      } flex relative w-[20px] transition-opacity 'svg:opacity-50'`}
                    >
                      <LogoutIcon
                        svg=" opacity-50 rotate-180"
                        className=" fill-white "
                      />
                    </div>
                    <p className="text-p text-white text-base">
                      {sign[isArabic ? "ar" : "en"]}
                    </p>
                  </div>
                </button>
              </a> */}
            </div>

            <LangSwitch />
            <div className="flex items-center justify-center">
              {links.map((link, index) => (
                <div
                  className=" h-[30px] flex items-center justify-center"
                  key={`footer__link__${index}`}
                >
                  <a
                    target={link.href === "/" ? undefined : "_blank"}
                    rel="noreferrer"
                    className="flex "
                    href={link.href}
                  >
                    <div className="mx-4 w-10 h-10 relative">
                      <img
                        alt={link.alt}
                        src={link.src}
                        className="w-full h-full flex"
                      />
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </NavMenuContainer>
      {/* <NavBg isLong={location.pathname === PATH_LANDING.home} /> */}
      <div className="flex gap-5 items-center p-2 w-full ">
        <button
          type="button"
          className="flex absolute right-6 xs:right-10 justify-center z-20 p-2 items-center"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <MenuIcon
            svg=" w-[26px] h-[30px] cursor-pointer "
            className=" fill-white"
          />
        </button>
        <div className="flex flex-1 items-center">
          <Link
            to={PATH_LANDING.home}
            className="relative z-30 mx-auto  w-[120px]"
          >
            <img src={dinarLogo} alt="dinar logo" />
          </Link>
        </div>
      </div>
    </div>
  );
}
