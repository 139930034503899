import React from "react";
import { LoadingIcon } from "../Icons";

export default function LandingButton({
  title,
  onClick,
  width,
  isLoading,
  type = "button",
}: {
  title: React.ReactNode;
  onClick?: () => void;
  width?: string;
  isLoading?: boolean;
  type?: "button" | "submit";
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`relative flex justify-around rounded-[20px] border border-[#6da0ca]  items-center py-2 px-6  bg-[#2e76b3] text-white ${
        width ? width : ""
      } `}
    >
      {title}
      {isLoading ? (
        <LoadingIcon className={` animate-spin origin-center fill-white }`} />
      ) : (
        <></>
      )}
    </button>
  );
}

export  function LandingButtonBlue({
  title,
  onClick,
  width,
  isLoading,
  type = "button",
  disabled,
}: {
  title: React.ReactNode;
  onClick?: () => void;
  width?: string;
  isLoading?: boolean;
  type?: "button" | "submit";
  disabled?: boolean;
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`relative flex justify-around rounded-[20px] border bg-[#1E4384] font-bold  items-center py-2 px-6 h-auto   text-white ${
        width ? width : ""
      } `}
      disabled={disabled}
    >
      {title}
      {isLoading ? (
        <LoadingIcon className={` animate-spin origin-center fill-white }`} />
      ) : (
        <></>
      )}
    </button>
  );
}

export function LandingButtonWhite({
  title,
  onClick,
  width,
  isLoading,
  type = "button",
}: {
  title: React.ReactNode;
  onClick?: () => void;
  width?: string;
  isLoading?: boolean;
  type?: "button" | "submit";
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`relative flex justify-around rounded-[20px] border border-white font-bold  items-center py-2 px-6 h-auto  bg-white text-black ${
        width ? width : ""
      } `}
    >
      {title}
      {isLoading ? (
        <LoadingIcon className={` animate-spin origin-center fill-white }`} />
      ) : (
        <></>
      )}
    </button>
  );
}
