import { useLocation } from "@reach/router";
import { StaticQuery, graphql } from "gatsby";
import React, { useEffect } from "react";
import sal from "sal.js";
import "../../../node_modules/sal.js/dist/sal.css";
import SEO from "../../components/HeadWrapper";
import LandingFooter from "../../components/Landing/LandingFooter";
import LandingNav from "../../components/Landing/LandingNavBar";
import { LanguageProvider } from "../../contexts/LanguageContext";
import { ModalProvider } from "../../contexts/ModalContext";
import { PATH_LANDING } from "../../paths";
const seoTitles: { [key: string]: string } = {
  "": "homeTitle",
  about: "aboutTitle",
  contact: "contactTitle",
  faq: "faqTitle",
  fundraiser: "fundraiserTitle",
  investor: "investorTitle",
  opportunities: "opportunitiesTitle",
  policy: "policyTitle",
  privacypolicy: "privacyAndPolicyTitle",
  blog: "blogTitle",
  news: "newsTitle",
};

export default function LandingLayoutContainer(props: {
  children: React.ReactNode;
  outsider?: React.ReactNode;
  margin?: string;
}) {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          sanityNewsPage {
            title {
              en
              ar
            }
          }
          sanityBlogPage {
            title {
              en
              ar
            }
          }
          sanityHome {
            title {
              ar
              en
            }
          }
          sanityAbout {
            title {
              ar
              en
            }
          }
          sanityInvestorGuide {
            title {
              ar
              en
            }
          }
          sanityFundraiserGuide {
            title {
              ar
              en
            }
          }
          sanityPrivacyAndPolicy {
            title {
              ar
              en
            }
          }
          sanityConditionsAndTerms {
            title {
              ar
              en
            }
          }
          sanityContactUs {
            title {
              ar
              en
            }
          }
          sanityComplaints {
            title {
              ar
              en
            }
          }
          sanityFaqPage {
            title {
              ar
              en
            }
          }
          sanityFooter {
            title {
              ar
              en
            }
            footerTitle {
              ar
              en
            }
            footerSubTitle {
              ar
              en
            }
            twitter
            linkedin
            instagram
            tiktok
            email
            mobileNo
            location {
              ar
              en
            }
            warning {
              description {
                ar
                en
              }
              title {
                ar
                en
              }
            }
            disclosures {
              description {
                ar
                en
              }
              title {
                ar
                en
              }
            }
          }
        }
      `}
      render={(data) => <LandingLayout {...props} data={data} />}
    />
  );
}

function LandingLayout({
  children,
  outsider,
  margin = " mb-28",
  data,
}: {
  children: React.ReactNode;
  outsider?: React.ReactNode;
  margin?: string;
  data: any;
}) {
  const location = useLocation();
  useEffect(() => {
    sal();
    
  }, []);

  // add useEffect to scroll to the top on page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);




  return (
    <LanguageProvider>
      <SEO type={seoTitles[location.pathname.replaceAll("/", "") as string]} />
      <ModalProvider>
        <LandingNav data={data} />
        <div
          className={` ${
            location.pathname === PATH_LANDING.home ? "" : " "
          }  allToleft z-20`}
        >
          {children}
        </div>
        <span className={` w-full flex ${margin} `} />
        {outsider}
        <LandingFooter data={data} />
      </ModalProvider>
    </LanguageProvider>
  );
}
