
import React, { createContext, ReactNode, useEffect, useReducer } from 'react';
import { ActionMap } from '../@types/ActionMap';
import { useLocation } from '@reach/router'
// ----------------------------------------------------------------------


export type ModalType = {
    children?: React.ReactNode;
    title: string;
    buttonTitle?: string;
    onClick?: () => Promise<void | boolean> | void | boolean;
    onClose?: () => Promise<void | boolean> | void | boolean;
    secondButtonTitle?: string;
    onSecondClick?: () => Promise<void | boolean> | void | boolean;
    isOpen: boolean;
    setIsOpen?: (value: boolean) => void | boolean;
    isCustom?: boolean
};


type ModalState = {
    isOpen: boolean;
    children: React.ReactNode;
    modalProps: ModalType | undefined;
};

enum Types {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
    INITIALIZE = 'INITIALIZE',
}

type ModalPayload = {
    [Types.OPEN]: undefined;
    [Types.CLOSE]: undefined;
    [Types.INITIALIZE]: ModalType;
};

export type ModalActions =
    ActionMap<ModalPayload>[keyof ActionMap<ModalPayload>];

const initialState: ModalState = {
    isOpen: false,
    children: <></>,
    modalProps: undefined,

};

const ModalReducer = (state: Partial<ModalState>, action: ModalActions) => {
    switch (action.type) {
        case Types.OPEN:
            return {
                ...state,
                isOpen: true,
            };

        case Types.CLOSE:
            return {
                ...state,
                isOpen: false,
            };
        case Types.INITIALIZE:
            if (!action.payload) return state
            return {
                ...state,
                modalProps: { ...action.payload },
                isOpen: action.payload.isOpen,
                isCustom: action.payload.isCustom
            };

        default:
            return state;
    }
};

type ModalContextType = {
    openModal: () => Promise<void> | void;
    closeModal: () => Promise<void> | void;
    initialize: (data: ModalType) => Promise<void> | void;
    isOpen?: boolean,
    setIsOpen: (value: boolean) => void | boolean,
};

const ModalContext = createContext<ModalContextType | null>(null);

// ----------------------------------------------------------------------

type ModalProviderProps = {
    children: ReactNode;
};

function ModalProvider({ children }: ModalProviderProps) {

    const location = useLocation()

    const [state, dispatch] = useReducer(ModalReducer, initialState);

    const openModal = async () => {
        dispatch({
            type: Types.OPEN,
        });
    };

    useEffect(() => {
        if (!state.isOpen) return
        setIsOpen(false)
    }, [location.pathname])

    const closeModal = async () => {
        dispatch({
            type: Types.CLOSE,
        });
    };

    const setIsOpen = (value: boolean) => {
        if (value) openModal()
        else closeModal()
    }

    const initialize = async (data: ModalType) => {
        dispatch({
            type: Types.INITIALIZE,
            payload: { ...state, ...data },
        });
    };

    return (
        <ModalContext.Provider
            value={{
                ...state,
                openModal,
                closeModal,
                initialize,
                setIsOpen: setIsOpen
            }}
        >
            {/* { state.modalProps ? (
                <Modal {...state.modalProps} isOpen={!!state.isOpen} />
            ) : null} */}
            <div style={{height: state.isOpen ? window.innerHeight+'px' : ''  }} className={  ` flex flex-col overflow-hidden max-w-[100vw] `}>
                {children}
            </div>
        </ModalContext.Provider>
    );
}

export { ModalContext, ModalProvider };
