import { Link } from "gatsby";
import React, { useState } from "react";
import useLanguage from "../../Hooks/useLang";
import { FirstFlowButton } from "../Buttons";
import { ChevronIcon } from "../Icons";
export type NavLinkProps = {
  href: string;
  title: {
    ar: string;
    en: string;
  };
  icon?: JSX.Element;
  src?: string;
  selected?: boolean;
  isButton?: boolean;
  root: string;
  multiple?: {
    title: {
      ar: string;
      en: string;
    };
    href: string;
  }[];
  close?: () => void;
};

export const NavMobileLink = ({
  href,
  title,
  icon,
  src,
  selected,
  isButton,
  multiple,
  close,
}: NavLinkProps) => {
  const isArabic = useLanguage()?.isArabic;
  const [isClicked, setIsClicked] = useState(false);
  return isButton ? (
    <Link to={href} className="mt-auto w-[160px] pb-3">
      <FirstFlowButton
        color="green"
        title={title?.[isArabic ? "ar" : "en"]}
        // onClick={() => navigate(href)}
      />
    </Link>
  ) : multiple ? (
    <div className="flex flex-col">
      <div
        className={`  ${
          isArabic ? "toRight" : "toLeft"
        } relative cursor-pointer flex items-center justify-start py-1 gap-4   `}
        onClick={() => setIsClicked(!isClicked)}
      >
        <span
          className={` absolute transition top-[0.12rem] ${
            isArabic ? "-right-4" : "-left-4"
          }  h-full w-[0.3rem] ${
            selected ? "bg-text-primary" : "bg-transparent"
          }  `}
        />
        <p className=" text-white text-base">{title?.[isArabic ? "ar" : "en"]}</p>
        <span className="mx-2">
          <ChevronIcon
            width={12}
            height={12}
            className="fill-white "
            svg={` ${
              isClicked ? "rotate-180" : "rotate-0"
            } transition-[transform]`}
          />
        </span>
      </div>
      <ul
        className="  flex flex-col list-none child:p-1 overflow-hidden transition-[height] duration-300 ease-in-out"
        style={{ height: isClicked ? 80 : 0 }}
      >
        {multiple?.map((item, index) => (
          <li key={`nav__mobile__item__${index}`} onClick={close}>
            <Link to={item.href + '#top'}>
              <div
                className={`  ${
                  isArabic ? "toRight" : "toLeft"
                } relative mt-2 text-gray-200 pr-4 cursor-pointer flex items-center justify-start py-1 gap-4 hover-child-span:bg-text-primary  `}
              >
                <p className=" text-white text-sm">
                  {item.title?.[isArabic ? "ar" : "en"]}
                </p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <Link to={href}>
      <div
        className={`  ${
          isArabic ? "toRight" : "toLeft"
        } relative cursor-pointer flex items-center justify-start py-1 gap-4 hover-child-span:bg-text-primary  `}
      >
        <span
          className={` absolute transition top-[0.12rem] ${
            isArabic ? "-right-4" : "-left-4"
          }  h-full w-[0.3rem] ${
            selected ? "bg-text-primary" : "bg-transparent"
          }  `}
        />
        <p className=" text-white text-base">{title?.[isArabic ? "ar" : "en"]}</p>
      </div>
    </Link>
  );
};

const NavLink = ({ href, title, selected, isButton }: NavLinkProps) => {
  return isButton ? (
    <Link to={href}>
      <a className="mt-auto w-[160px] pb-3">
        <FirstFlowButton
          color="green"
          title={title?.[useLanguage().isArabic ? "ar" : "en"]}
          // onClick={() => router.push(href)}
        />
      </a>
    </Link>
  ) : (
    <Link to={href}>
      <a>
        <div
          className={` cursor-pointer w-[60px] flex flex-col items-center justify-center pb-1 transition-[border]  border-b-4 ${
            selected ? "border-text-primary" : "border-b-transparent"
          }   `}
        >
          <p className=" text-white whitespace-nowrap ">
            {title?.[useLanguage().isArabic ? "ar" : "en"]}
          </p>
        </div>
      </a>
    </Link>
  );
};

export default NavLink;
