import React from "react";
import { FirstFlowButton } from "../../Buttons";
import LandingButton from "../../Buttons/LandingButton";
import LandingCard from "../../Cards/LandingCard";
import {
  ClockFillIcon,
  EmptyFileIcon,
  EnvelopeOpenIcon,
  HomeFileIcon,
  MoneyIcon,
} from "../../Icons";
import { LandingInvestCardType } from "../LandingInvestCard";

export const subs: LandingInvestCardType[] = [
  {
    onSubmit: () => null,
    titles: ["صكوك المرابحة", "", "20,000,000 ريال"],
    bottomInfo: [
      {
        title: "العائد على الاستثمار",
        value: " 15%",
      },
      {
        title: "الفترة",
        value: "18 شهر",
      },
    ],
    completedPercentage: 75,
    opportunityState: "active",
  },
  {
    time: new Date("7/16/2022"),
    onSubmit: () => null,
    titles: ["صكوك المرابحة", "", "20,000,000 ريال"],
    bottomInfo: [
      {
        title: "العائد على الاستثمار",
        value: " 15%",
      },
      {
        title: "الفترة",
        value: "18 شهر",
      },
    ],
    completedPercentage: 75,

    opportunityState: "active",
  },
  {
    time: new Date("9/16/2022"),
    counter: true,
    onSubmit: () => null,
    titles: ["صكوك المرابحة", "", "20,000,000 ريال"],
    bottomInfo: [
      {
        title: "العائد على الاستثمار",
        value: " 15%",
      },
      {
        title: "الفترة",
        value: "18 شهر",
      },
    ],
    status: {
      title: "فرصة قادمة",
      status: "warning",
    },
    opportunityState: "pending",
  },
  {
    time: new Date("7/16/2022"),
    counter: true,
    onSubmit: () => null,
    titles: ["صكوك المرابحة", "", "20,000,000 ريال"],
    bottomInfo: [
      {
        title: "العائد على الاستثمار",
        value: " 15%",
      },
      {
        title: "الفترة",
        value: "18 شهر",
      },
    ],
    status: {
      title: "فرصة مغلقة",
      status: "rejected",
    },
    opportunityState: "closed",
    completedPercentage: 100,
  },
];

export const LandingHeroTop = ({
  title,
  leftButton,
  rightButton,
}: {
  title: string;
  leftButton: string;
  rightButton: string;
}) => {
  return (
    <div
        className="flex flex-col mobile:mb-16 max-w-[90vw] mx-auto items-center"
    >
      <h1 className=" font-semibold 2xl:mt-[10vh] mobile:mb-12 text-6xl xs:text-3xl mobile:text-6xl ">
        {title}
      </h1>
      <div className="flex flex-wrap mx-auto mt-10 flex-col sm:flex-row sm:mt-0 gap-4">
        <div>
          <a target={"_blank"} href={`${process.env.GATSBY_APP_URL}/sign`}>
            <FirstFlowButton
              title={leftButton}
              color="white"
              outline
              padding=" py-3 px-8"
              type="button"
            />
          </a>
        </div>
        <div>
          <a
            target={"_blank"}
            href={`${process.env.GATSBY_APP_URL}/sign?type=fundraiser`}
          >
            <FirstFlowButton
              title={rightButton}
              color="white"
              outline
              padding=" py-3 px-8"
              type="button"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export const GetFundSectionElementMobile = ({
  Icon,
  title,
  description,
  mobileIcon,
}: {
  Icon: React.ReactNode;
  mobileIcon?: React.ReactNode;
  title: string;
  description: string;
}) => (
  <div className="flex z-10 bg-white py-4 relative flex-col gap-2 items-center">
    <div
      className={` ${
        mobileIcon ? "hidden" : "flex"
      }  justify-center items-center h-[116px] w-[116px] bg-background-blue-800  rounded-[40px] `}
    >
      {Icon}
    </div>
    {mobileIcon ? (
      <div className=" flex justify-center items-center h-[116px] w-[116px] bg-background-blue-800 rounded-[40px] ">
        {mobileIcon}
      </div>
    ) : (
      <></>
    )}

    <div className="flex  gap-2 flex-col items-center">
      <p className=" text-background-blue-800 flex text-2xl  font-medium text-center ">
        {title}
      </p>
      <p className="flex max-w-[150px] md:max-w-[200px] lg:max-w-[300px] text-lg  text-center text-background-gray-500 ">
        {description}
      </p>
    </div>
  </div>
);

export const GetFundSectionElement = ({
  Icon,
  title,
  description,
  mobileIcon,
}: {
  Icon: React.ReactNode;
  mobileIcon?: React.ReactNode;
  title: string;
  description: string;
}) => (
  <div className="flex z-10 bg-none  py-0 relative flex-col  gap-20 items-center">
    <div
      className={` flex justify-center items-center h-[90px] w-[90px] sm:h-[116px] sm:w-[116px] bg-white rounded-[40px] `}
    >
      {Icon}
    </div>
    <span className=" flex rounded-full absolute top-[140px] bg-text-primary w-[20px] z-10 h-[20px] " />
    <div className="flex  mt-5 gap-2 flex-col items-center">
      <p className=" text-white flex text-xl md:text-2xl lg:text-3xl font-medium text-center ">
        {title}
      </p>
      <p className="flex max-w-[150px] md:max-w-[200px] lg:max-w-[300px]  text-sm lg:text-base xl:text-lg text-center  text-text-gray-100 ">
        {description}
      </p>
    </div>
  </div>
);

export const getFundedSteps = [
  {
    title: "سجل في دقائق",
    description:
      'تسجيل المعلومات و ارفاق المستندات اللازمة بما لا يتجاوز " دقائق',
    Icon: (
      <ClockFillIcon
        height={48}
        width={48}
        svg=" rounded-full overflow-hidden"
        className2=" fill-white xs:fill-transparent stroke-[10px] stroke-white xs:stroke-text-primary "
        className=" xs:fill-text-primary fill-background-blue-800"
      />
    ),
  },
  {
    title: "مراجعة طلب التمويل",
    description:
      "یقوم الفریق الائتماني بمراجعة طلب التمویل وبما لا یتجاوز یومین",
    Icon: (
      <HomeFileIcon height={48} width={36} className="  fill-text-primary" />
    ),
    mobileIcon: (
      <EmptyFileIcon height={48} width={36} className="  fill-white" />
    ),
  },
  {
    title: "الحصول على التمویل",
    description:
      "یتم طرح الفرصة الاستثماریة للمستثمرین واكمال المبلغ خلال 10 أیام عمل من اكتمال التسجیل",
    Icon: (
      <MoneyIcon
        height={48}
        width={54}
        className="  fill-text-white xs:fill-text-primary"
      />
    ),
  },
];

export const HeroBg = ({ color }: { color?: string }) => (
  <span
    className={`flex bg-dashboard w-full lg:border-t-[20vw] ${
      color ? color : "border-t-background-gray-200"
    }  border-l-[100vw] border-l-background-blue-800 `}
  />
);

export const Container = ({ children }: { children: React.ReactNode }) => (
  <div className="flex  w-full  ">
    <div className="flex  flex-col  w-[92%] sm:w-11/12  max-w-[1280px] min-w-[300px] mx-auto">
      {children}
    </div>
  </div>
);

export const LandingHomeContainer = ({
  margin = "xs:my-16 mx-auto ",
  bg = "bg-white",
  children,
  border = "border-border-gray-100  border",
  padding = " p-6 sm:p-10",
}: {
  margin?: string;
  bg?: string;
  border?: string;
  padding?: string;
  children: React.ReactNode;
}) => (
  <div
    className={`z-10 ${bg} flex max-w-[79rem] w-[95%] sm:w-[90%] ${margin}  rounded-[40px] items-center relative justify-center ${padding} ${border} `}
  >
    {children}
  </div>
);

export const LandingHomeLayout = ({
  children,
  margin = " my-0 xs:my-16",
}: {
  children: React.ReactNode;
  margin?: string;
}) => (
  <div
    className={`z-20 relative flex max-w-[79rem] w-[90%] ${margin} mx-auto `}
  >
    {children}
  </div>
);

export const CarouselButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) => (
  <button
    onClick={() => onClick()}
    className="flex relative w-7 h-7 rounded-[5px] justify-center items-center overflow-hidden hover-child-span:opacity-100 "
  >
    <span className="h-full w-full bg-white transition opacity-80 absolute top-0 left-0 " />
    {children}
  </button>
);

export const LandingMail = ({
  onClick,
  setInput,
  input,
  isLoading,
  data,
}: {
  onClick: () => void;
  setInput: (string: string) => void;
  input: string;
  isLoading: boolean;
  data: {
    title: string;
    placeholder: string;
    description: string;
    buttonTitle: string;
  };
}) => {
  return (
    <LandingCard stripesWidth="" stripesHeight="" cardHeight=" h-auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onClick();
        }}
        className="flex flex-col py-10 sm:py-20 items-center justify-center w-full relative gap-10  z-20"
      >
        <EnvelopeOpenIcon svg=" w-10 h-10 xs:w-auto xs:h-auto" />
        <p className=" max-w-[90%] tracking-wider mx-auto text-xl md:text-2xl text-white">
          {data?.description}
        </p>
        <div className=" justify-center flex flex-wrap w-11/12  gap-10 mt-5">
          <input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className=" col-span-2 rounded-[20px] w-full md:w-[300px] xl:w-[520px] h-[50px] flex items-center text-black px-4 bg-white"
            placeholder={data?.placeholder}
            type="email"
            required
          />
          <LandingButton
            title={data?.buttonTitle}
            width=" w-[150px] md:w-[187px]"
            isLoading={isLoading}
            type="submit"
          />
        </div>
      </form>
    </LandingCard>
  );
};
