import React from "react";
import coloredStripe from '../../assets/coloredStripesBg.svg'
import homeWhiteBoxBg from '../../assets/whiteStripesBg.png'
import whiteBoxBg from '../../assets/stripesWhiteBg.svg'



const LandingCard = ({
    children,
    cardHeight = ' h-[367px]',
    stripesHeight = ' h-[462px]',
    stripesWidth= 'w-[50%]',
    white,
}: {
    children: React.ReactNode,
    cardHeight?: string,
    stripesHeight?: string,
    stripesWidth?: string,
    white?: boolean;
}) => {
    return (
        <div className={` flex rounded-[40px] overflow-hidden  relative ${white ? 'bg-white border border-gray-200' : 'bg-landing'} shadow-homeBar w-full ${cardHeight} `}>
            {children}
            <div className={` flex absolute bottom-0 left-0 z-0 ${stripesHeight}`}>
                <img className={`  w-[100%]  h-auto mt-auto mr-auto o `}
                    src={ !white ? whiteBoxBg : coloredStripe }
                    alt="background" />
            </div>
        </div>
    );
};

export default LandingCard