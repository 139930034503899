import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import useLanguage from "../../../Hooks/useLang";
import dinarLogo from "../../../assets/dinarLogoWhiteText.svg";
import { login } from "../../../constants/dualLang";
import { PATH_LANDING } from "../../../paths";
import LandingButton from "../../Buttons/LandingButton";
import LangSwitch from "../../LangSwitch";
import { LandingNav } from "../../NavButtons";
import { NavLinkProps } from "../../NavButtons/NavLink";
import { Container } from "../LandingHome/LandingHomeComponents";

const LandingNavBg = () => {
  const pathname = useLocation().pathname;

  const isFundraiserBg =
    pathname.replaceAll("/", "") ===
      PATH_LANDING.fundraiser.replaceAll("/", "") ||
    pathname.replaceAll("/", "") ===
      PATH_LANDING.fundraiser_gads.replaceAll("/", "") ||
    pathname.includes(PATH_LANDING.fundraiser_realestate);
  return isFundraiserBg ? (
    <div
      className={`absolute bg-[#1969ad] w-[102vw] top-0 -left-2 z-0 h-[200vh] `}
      // style={{
      //   background:
      //     "transparent linear-gradient(30deg, #085897 0%, #1B70B4 100%) 0% 0% no-repeat padding-box",
      // }}
    />
  ) : (
    <div className={`absolute   w-[102vw] top-0 -left-2 z-0h-[50vh] `}>
      {/* <img
        alt="Navigation background"
        src={landingBgSmall3}
        className="w-full"
      /> */}
    </div>
  );
};

export default function NavBasic({
  navItems,
  isScrolled,
}: {
  navItems: NavLinkProps[];
  isScrolled?: boolean;
}) {
  const location = useLocation();
  const isArabic = useLanguage()?.isArabic;
  return (
    <Container>
      <div className="flex toRight z-50 flex-1 gap-10 w-full -reverse items-center justify-between">
        <div className="flex  flex-1 gap-y-4 gap-x-32 w-full">
          <nav className={`flex flex-row  gap-12 w-full  `}>
            <Link to={PATH_LANDING.home} className=" cursor-pointer ">
              <img height={40} width={100} src={dinarLogo} alt="dinar logo" />
            </Link>
            <div
              className={`flex flex-1 child:my-auto ${
                isArabic ? "" : "toLeft justify-center items-center"
              }  min-w-[350px] gap-8 lg:gap-12 xl:gap:20 `}
            >
              {navItems.map((item, index) => (
                <LandingNav
                  index={index}
                  key={`nav__item__${index}`}
                  title={item.title?.[useLanguage().isArabic ? "ar" : "en"]}
                  href={item.href + '#top'}
                  icon={item.icon}
                  selected={
                    false
                    // location.pathname.includes("blog")
                    //   ? item.href === PATH_LANDING.blog
                    //   : location.pathname.includes("news")
                    //   ? item.href === PATH_LANDING.news
                    //   : location.pathname === item.href
                  }
                  multiple={item.multiple}
                />
              ))}
              <div className=" sm:mb-0 mt-auto mr-auto mb-6 flex items-center justify-end gap-2 ">
                <LangSwitch />
                <a target={"_blank"} href={`https://app.dinar.sa/`}>
                  <LandingButton
                    title={login[useLanguage().isArabic ? "ar" : "en"]}
                  />
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </Container>
  );
}
