import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import useLanguage from "../../../Hooks/useLang";
import dinarLogo from "../../../assets/dinarLogoWhiteText.svg";
import danger from "../../../assets/icons/danger.svg";
import gps from "../../../assets/icons/gps.svg";
import instagram from "../../../assets/icons/instagram.svg";
import linkedin from "../../../assets/icons/linkedin.svg";
import mail from "../../../assets/icons/mail.svg";
import phone from "../../../assets/icons/phone.svg";
import tiktok from "../../../assets/icons/tiktok.svg";
import twitter from "../../../assets/icons/twitter.svg";

import { PATH_LANDING } from "../../../paths";
import Text from "../../Texts";

export default function LandingFooter({ data }: { data: any }) {
  const location = useLocation();

  const isArabic = useLanguage().isArabic;
  const footerLinks = [
    {
      title: {
        en: "About Us",
        ar: "من نحن",
      },
      elements: [
        {
          title: {
            ar: data?.sanityInvestorGuide?.title?.ar || "المستثمر",
            en: data?.sanityInvestorGuide?.title?.en || "Investor",
          },
          url: "/investor",
        },
        {
          title: {
            ar: data?.sanityFundraiserGuide?.title?.ar || "طالب التمويل",
            en: data?.sanityFundraiserGuide?.title?.en || "Fundraiser",
          },

          url: "/fundraiser",
        },
        {
          title: {
            ar: data?.sanityBlogPage?.title?.ar || "المدونة",
            en: data?.sanityBlogPage?.title?.en || "Blog",
          },

          url: "/blog",
        },
        {
          title: {
            ar: data?.sanityNewsPage?.title?.ar || "الأخبار",
            en: data?.sanityNewsPage?.title?.en || "News",
          },

          url: "/news",
        },
        {
          title: {
            ar: data?.sanityCmaPage?.title?.ar || "إفصاحات هيئة السوق المالية",
            en:
              data?.sanityCmaPage?.title?.en ||
              "CMA Disclosures and Announcements",
          },

          url: "/cma",
        },
        {
          title: {
            ar: data?.sanityComplaints?.title?.ar || "شكاوي",
            en: data?.sanityComplaints?.title?.en || "Complaints",
          },

          url: "/complaints",
        },
      ],
    },
    {
      title: {
        en: "Other",
        ar: "المزيد",
      },
      elements: [
        {
          title: {
            ar: data?.sanityConditionsAndTerms?.title?.ar || "الشروط والأحكام",
            en:
              data?.sanityConditionsAndTerms?.title?.en || "Conditions & Terms",
          },

          url: "/policy",
        },
        {
          title: {
            ar: data?.sanityPrivacyAndPolicy?.title?.ar || "السياسة و الخصوصية",
            en: data?.sanityPrivacyAndPolicy?.title?.en || "Privacy and Policy",
          },

          url: "/privacypolicy",
        },
        {
          title: {
            ar: data?.sanityFaqPage?.title?.ar || "اسئلة شائعة",
            en: data?.sanityFaqPage?.title?.en || "Faq",
          },

          url: "/faq",
        },
        {
          title: {
            ar: data?.sanityAbout?.title?.ar || "من نحن",
            en: data?.sanityAbout?.title?.en || "About us",
          },

          url: "/about",
        },
        {
          title: {
            ar: data?.sanityContactUs?.title?.ar || "تواصل معنا",
            en: data?.sanityContactUs?.title?.en || "Contact Us",
          },

          url: "/contact",
        },
      ],
    },
  ];

  const socialLinks = [
    {
      alt: "linkedin",
      src: linkedin,
      href: data?.sanityFooter?.linkedin,
    },
    {
      alt: "instagram",
      src: instagram,
      href: data?.sanityFooter?.instagram,
    },
    {
      alt: "twitter",
      src: twitter,
      href: data?.sanityFooter?.twitter,
    },
    {
      alt: "tiktok",
      src: tiktok,
      href: data?.sanityFooter?.tiktok,
    },
  ];

  const contacts = [
    {
      iconSrc: phone,
      content: data?.sanityFooter?.mobileNo,
      url: `tel:${data?.sanityFooter?.mobileNo}`,
    },
    {
      iconSrc: mail,
      content: data?.sanityFooter?.email,
      url: `mailto:${data?.sanityFooter?.email}`,
    },
    {
      iconSrc: gps,
      content: data?.sanityFooter?.location?.[isArabic ? "ar" : "en"],
    },
  ];

  return (
    <footer className=" flex flex-col text-sm sm:text-base gap-12 w-full justify-center z-10 px-4 sm:px-12 lg:px-20 py-16  bg-[#111827]">
      <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-y-12 gap-4 sm:gap-12 xl:gap-24 text-[#CBD5E0]">
        <div className="flex flex-col col-span-2  md:col-span-1 xl:col-span-2 gap-4 sm:gap-10">
          <Link to={PATH_LANDING.home} className=" cursor-pointer ">
            <img height={40} width={100} src={dinarLogo} alt="dinar logo" />
          </Link>
          <a
            target="_blank"
            href={`tel:${data?.sanityFooter?.footerTitle?.[
              isArabic ? "ar" : "en"
            ].replace(" ", "")}`}
          >
            <p
              className={`sm:text-2xl table  ${
                isArabic ? "text-right" : "text-left"
              } toLeft `}
            >
              {data?.sanityFooter?.footerTitle?.[isArabic ? "ar" : "en"]}
            </p>
          </a>

          <Text extraCss=" text-xs sm:text-base">
            {data?.sanityFooter?.footerSubTitle?.[isArabic ? "ar" : "en"]}
          </Text>
          <div className="flex flex-wrap gap-2 sm:gap-4  items-center">
            {socialLinks
              .filter((link) => link.href)
              ?.map((link, index) => (
                <a
                  key={`footer__link__${index}`}
                  target="_blank"
                  href={link.href}
                >
                  <img
                    className=" sm:w-10 sm:h-10 w-7 h-7"
                    src={link.src}
                    alt={link.alt}
                  />
                </a>
              ))}
          </div>
        </div>
        {footerLinks.reverse().map((footerLink, index) => (
          <div key={`footer__link__${index}`} className="flex flex-col ">
            <div className="flex  flex-col gap-4 sm:gap-6">
              <p className="text-lg sm:text-xl font-bold mb-4">
                {footerLink.title?.[isArabic ? "ar" : "en"]}
              </p>
              {footerLink.elements.map((element, index) => (
                <Link
                  className=" opacity-80 "
                  key={`footer__link__element__${index}`}
                  to={element.url + "#top"}
                >
                  {element.title?.[isArabic ? "ar" : "en"]}
                </Link>
              ))}
            </div>
          </div>
        ))}
        <div className="flex flex-col  ">
          <div className="flex flex-col gap-4 sm:gap-6">
            <p className="text-xl font-bold mb-4">
              {data?.sanityContactUs?.title?.[isArabic ? "ar" : "en"]}{" "}
            </p>
            {contacts.map((element, index) =>
              element?.url ? (
                <a
                  key={index}
                  className="flex  break-words gap-2 items-center"
                  href={element.url}
                >
                  <img
                    className="sm:w-6 sm:h-6 w-4 h-4"
                    src={element.iconSrc}
                  />
                  {element.content}
                </a>
              ) : (
                <p key={index} className="flex  break-words gap-2 items-center">
                  <img
                    className="sm:w-6 sm:h-6 w-4 h-4"
                    src={element.iconSrc}
                  />
                  {element.content}
                </p>
              )
            )}
          </div>
        </div>
      </div>
      <div
        className="flex w-full flex-col text-white text-start rounded-[20px] sm:rounded-[50px] gap-5 p-6"
        style={{
          background:
            "linear-gradient(240.49deg, #111827 -13.97%, #1E273B 59.47%)",
        }}
      >
        <p className=" flex gap-2  items-center font-bold  text-xl sm:text-2xl">
          <img src={danger} alt="تحذیر " className=" w-5 h-5 sm:w-8 sm:h-8" />
          {
            data?.sanityFooter?.warning?.title?.[
              useLanguage().isArabic ? "ar" : "en"
            ]
          }
        </p>
        <p className=" text-base sm:text-lg leading-10 sm:leading-10">
          {
            data?.sanityFooter?.warning?.description?.[
              useLanguage().isArabic ? "ar" : "en"
            ]
          }
        </p>
      </div>
      <div
        className="flex w-full flex-col text-white text-start rounded-[20px] sm:rounded-[50px] gap-5 p-6"
        style={{
          background:
            "linear-gradient(240.49deg, #111827 -13.97%, #1E273B 59.47%)",
        }}
      >
        <p className=" flex gap-2  items-center font-bold  text-xl sm:text-2xl">
          {/* <img src={danger} alt="تحذیر " className=" w-5 h-5 sm:w-8 sm:h-8" /> */}
          {
            data?.sanityFooter?.disclosures?.title?.[
              useLanguage().isArabic ? "ar" : "en"
            ]
          }
        </p>
        <p className=" text-base sm:text-lg leading-10 sm:leading-10">
          {
            data?.sanityFooter?.disclosures?.description?.[
              useLanguage().isArabic ? "ar" : "en"
            ]
          }
        </p>
      </div>
    </footer>
  );
}
