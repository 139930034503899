import { useContext, useEffect } from 'react';
//
import {ModalContext, ModalType } from '../contexts/ModalContext';

// ----------------------------------------------------------------------

const useModal = (modalProps?: ModalType) => {

  const context = useContext(ModalContext);

  if (!context) throw new Error('Modal context must be use inside ModalProvider');

  useEffect(() => {
    if(modalProps) context.initialize({...modalProps})
  },[])


  return context;
};

export default useModal;
