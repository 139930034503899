import React from "react";
import { PATH_LANDING } from "../../../paths";
import Nav from "./Nav";

export type NavLinkProps = {
  href: string;
  title: {
    ar: string;
    en: string;
  };
  icon?: JSX.Element;
  src?: string;
  selected?: boolean;
  isButton?: boolean;
  root: string;
  multiple?: {
    title: {
      ar: string;
      en: string;
    };
    href: string;
  }[];
};

export default function LandingNav({ data }: { data: any }) {
  const blog = {
    title: {
      ar: data?.sanityBlogPage?.title?.ar,
      en: data?.sanityBlogPage?.title?.en,
    },
    href: PATH_LANDING.blog,
    root: PATH_LANDING.blog,
  };

  const news = {
    title: {
      ar: data?.sanityNewsPage?.title?.ar,
      en: data?.sanityNewsPage?.title?.en,
    },
    href: PATH_LANDING.news,
    root: PATH_LANDING.news,
  };
  const navsItems: NavLinkProps[] = [
    {
      title: {
        ar: data?.sanityHome?.title?.ar,
        en: data?.sanityHome?.title?.en,
      },
      href: PATH_LANDING.home,
      root: PATH_LANDING.home,
    },
    {
      title: {
        ar: data?.sanityInvestorGuide?.title?.ar,
        en: data?.sanityInvestorGuide?.title?.en,
      },
      href: PATH_LANDING.investor,
      root: PATH_LANDING.investor,
    },

    {
      title: {
        ar: data?.sanityFundraiserGuide?.title?.ar,
        en: data?.sanityFundraiserGuide?.title?.en,
      },
      href: PATH_LANDING.fundraiser,
      root: PATH_LANDING.fundraiser,
      multiple: [
        {
          title: {
            ar: "منتج التمويل العقاري",
            en: "Real estate financing product",
          },
          href: PATH_LANDING.fundraiser,
        },
        {
          title: {
            ar: "منتجات التمويل الأخرى",
            en: "Other financing products",
          },
          href: PATH_LANDING.fundraiserOther,
        },
      ],
    },

    {
      title: {
        ar: data?.sanityAbout?.title?.ar,
        en: data?.sanityAbout?.title?.en,
      },
      href: PATH_LANDING.about,
      root: PATH_LANDING.about,
    },
    news,
    // news,
  ];

  return <LandingNavComponent links={navsItems} />;
}

function LandingNavComponent({ links }: { links: NavLinkProps[] }) {
  return <Nav navsItems={[...links]} />;
}
