exports.components = {
  "component---src-components-landing-landing-article-index-tsx": () => import("./../../../src/components/Landing/LandingArticle/index.tsx" /* webpackChunkName: "component---src-components-landing-landing-article-index-tsx" */),
  "component---src-components-landing-landing-news-landing-news-article-tsx": () => import("./../../../src/components/Landing/LandingNews/LandingNewsArticle.tsx" /* webpackChunkName: "component---src-components-landing-landing-news-landing-news-article-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-cma-tsx": () => import("./../../../src/pages/cma.tsx" /* webpackChunkName: "component---src-pages-cma-tsx" */),
  "component---src-pages-complaints-tsx": () => import("./../../../src/pages/complaints.tsx" /* webpackChunkName: "component---src-pages-complaints-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-fundraiser-campaign-tsx": () => import("./../../../src/pages/fundraiser/campaign.tsx" /* webpackChunkName: "component---src-pages-fundraiser-campaign-tsx" */),
  "component---src-pages-fundraiser-gads-index-tsx": () => import("./../../../src/pages/fundraiser/gads/index.tsx" /* webpackChunkName: "component---src-pages-fundraiser-gads-index-tsx" */),
  "component---src-pages-fundraiser-index-tsx": () => import("./../../../src/pages/fundraiser/index.tsx" /* webpackChunkName: "component---src-pages-fundraiser-index-tsx" */),
  "component---src-pages-fundraiser-other-campaign-tsx": () => import("./../../../src/pages/fundraiser/other/campaign.tsx" /* webpackChunkName: "component---src-pages-fundraiser-other-campaign-tsx" */),
  "component---src-pages-fundraiser-other-index-tsx": () => import("./../../../src/pages/fundraiser/other/index.tsx" /* webpackChunkName: "component---src-pages-fundraiser-other-index-tsx" */),
  "component---src-pages-gads-tsx": () => import("./../../../src/pages/gads.tsx" /* webpackChunkName: "component---src-pages-gads-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investor-tsx": () => import("./../../../src/pages/investor.tsx" /* webpackChunkName: "component---src-pages-investor-tsx" */),
  "component---src-pages-leap-2024-full-tsx": () => import("./../../../src/pages/leap2024/full.tsx" /* webpackChunkName: "component---src-pages-leap-2024-full-tsx" */),
  "component---src-pages-leap-2024-index-tsx": () => import("./../../../src/pages/leap2024/index.tsx" /* webpackChunkName: "component---src-pages-leap-2024-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */)
}

