import { Link, navigate } from "gatsby";
import React from "react";
import useLanguage from "../../Hooks/useLang";
import { ChevronIcon } from "../Icons";

export type LandingNavProps = {
  href: string;
  title: string;
  icon?: JSX.Element;
  src?: string;
  selected?: boolean;
  multiple?: {
    title: {
      ar: string;
      en: string;
    };
    href: string;
  }[];
  index: number;
};

export const NavMobileLink = ({
  href,
  title,
  icon,
  src,
  selected,
}: LandingNavProps) => (
  <Link to={href}>
    <div
      className={` relative cursor-pointer flex items-center justify-start py-1 gap-4  `}
    >
      <span
        className={` absolute top-[0.12rem] -right-4 h-full w-[0.3rem] ${
          selected ? "bg-text-primary" : "bg-transparent"
        }  `}
      />
      <p className="text-p text-white text-xl">{title}</p>
    </div>
  </Link>
);

const LandingNav = ({
  href,
  title,
  selected,
  multiple,
  index,
}: LandingNavProps) => {
  const isArabic = useLanguage().isArabic;
  if (multiple)
    return (
      <button
        className={`${
          index < 3 ? "flex" : "hidden lg:flex"
        }   h-6 group relative  cursor-pointer flex items-center justify-center pb-1 transition-[border]  border-b-4 ${
          !multiple && "hover:border-text-primary"
        } ${
          selected ? "border-text-primary" : "border-b-transparent"
        }  p-1 text-md h-full   text-white  font-normal flex gap=2 `}
      >
        {title}
        {multiple ? (
          <span className="mx-2">
            <ChevronIcon width={12} height={12} className="fill-white " />
          </span>
        ) : (
          <></>
        )}
        <ul
          className={`  rounded-xl  group-hover:flex hidden absolute top-[105%] right-2 z-10 bg-white shadow flex-col items-start justify-center   text-center  `}
        >
          {multiple?.map((item, index) => (
            <li
              onClick={() => navigate(item.href)}
              key={`nav__item__${index}`}
              className={` cursor-pointer w-11/12 mx-auto ${
                index < multiple?.length - 1 ? " " : ""
              } flex py-2 flex-col items-center justify-center    ${
                selected ? "border-text-primary" : "border-r-transparent"
              } hover:text-text-blue hover:bg-[#eef1f5] m-1  text-black transistion text-md opacity-0 group-hover:opacity-100 transition-opacity  whitespace-nowrap  font-normal px-4 `}
            >
              {item.title?.[isArabic ? "ar" : "en"]}
            </li>
          ))}
        </ul>
      </button>
    );
  return (
    <Link to={href}>
      <button
        className={` ${
          index < 3 ? "flex" : "hidden lg:flex"
        }  h-6 group relative  cursor-pointer flex  items-center justify-center pb-1 transition-[border]  border-b-4 ${
          !multiple && "hover:border-text-primary"
        } ${
          selected ? "border-text-primary" : "border-b-transparent"
        }  p-1 text-md   text-white  font-normal flex gap=2 `}
      >
        {title}
      </button>
    </Link>
  );
};

export default LandingNav;
