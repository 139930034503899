import React from 'react';
import { LoadingIcon } from '../Icons';

interface IParams {
    type?: 'button' | 'submit';
    w?: string | number;
    onClick?: () => void;
    title: string | JSX.Element;
    outline?: boolean;
    disabled?: boolean;
    color?: keyof typeof colors;
    height?: string;
    padding?: string;
    isLoading?: boolean;
    popUp?: string;
    isTransparent?: boolean;
    fontSize?: string
}

const colors = {
    white: {
        default:
            ' bg-transparent text-white shadow-f1White hover:shadow-f1WhiteHover border-none',
        outline:
            'bg-transparent text-white shadow-f1White hover:shadow-f1WhiteHover border-white ',
        disabled:
            'bg-green-300  text-white  border-none',
    },
    green: {
        default:
            ' bg-text-primary text-white shadow-f1ButtonGreen hover:shadow-f1ButtonGreenHover border-none',
        outline:
            'bg-white text-text-primary shadow-f1ButtonGreen hover:shadow-f1ButtonGreenHover border-text-primary ',
        disabled:
            'bg-green-300  text-white   border-none',
    },
    blue: {
        default:
            'bg-text-primary text-white shadow-f1Button hover:shadow-f1ButtonHover border-none',
        outline:
            'bg-white text-text-blue shadow-f1Button hover:shadow-f1ButtonHover border-background-blue-500',
        disabled:
            'bg-background-blue-400 text-white  border-none',
    },
    sky: {
        default:
            'bg-background-sky text-text-blue shadow-f1ButtonSky hover:shadow-f1ButtonSkyHover border-none',
        outline:
            'bg-white text-text-blue shadow-f1ButtonSky hover:shadow-f1ButtonSkyHover border-background-sky',
        disabled:
            'bg-background-blue-400 text-white  border-none',
    },
    red: {
        default:
            'bg-red-500 text-white shadow-[] shadow-f1ButtonRed hover:shadow-f1ButtonRedHover border-none',
        outline:
            'bg-white text-[#C7181F] shadow-f1ButtonRed hover:shadow-f1ButtonRedHover border-red-500',
        disabled:
            'bg-red-400 text-white border-none',
    },
};

const returnButtonType = (outline: boolean, disabled: boolean) =>
    disabled ? 'disabled' : outline ? 'outline' : 'default';

const FirstFlowButton = ({
    type = 'submit',
    onClick,
    title,
    outline = false,
    disabled = false,
    color = 'blue',
    height,
    padding,
    isLoading,
    popUp,
    isTransparent,
    fontSize,
    w
}: IParams) => (
    <button
        title={popUp}
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={`
        ${height ? height : ' h-auto '} 
        transition
        border-[1px]
        flex justify-center items-center gap-4
        ${fontSize ? fontSize : ' text-base ' }
        font-medium 
        ${w ? w : 'w-full'}
        rounded-2xl  
        ${padding ? padding : ' px-2  py-2'}
        ${colors[color][returnButtonType(outline, disabled)]}
        ${isTransparent ? 'bg-transparent' : ''}
    `}
    >
        {title}
        {isLoading ? (
            <LoadingIcon
                className={` animate-spin origin-center  ${
                    colors[color][returnButtonType(outline, disabled)]
                }`}
            />
        ) : (
            <></>
        )}
    </button>
);

export default FirstFlowButton;
