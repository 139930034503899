import { useContext } from 'react';
//
import { LanguageContext } from '../contexts/LanguageContext';

// ----------------------------------------------------------------------

const useLanguage = () => {

  const context = useContext(LanguageContext);

  if (!context) throw new Error('Language context must be use inside LanguageProvider');

  return context;
};

export default useLanguage;
