import React from "react";

export default function Text({
  children,
  className = "text-base text-[#718096]",
  extraCss,
}: {
  children: React.ReactNode;
  className?: string;
  extraCss?: string;
}) {
  return <p className={className + " " + extraCss}>{children}</p>;
}
