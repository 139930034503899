import React from "react";
import useLanguage from "../../Hooks/useLang";
import LandingButton from "../Buttons/LandingButton";
import { LanguageIcon } from "../Icons";

const LangSwitch = () => {
  const { isArabic, switchLanguage } = useLanguage();

  return (
    <LandingButton
      onClick={() => switchLanguage()}
      title={
        <>
          <LanguageIcon className="fill-text-blue" />
          {isArabic ? "English" : "العربية"}
        </>
      }
    />
  );
};

export default LangSwitch;
