export const opportunityLang = {
  title: {
    ar: "الفرص الاستثمارية",
    en: "Opportunities",
  },
  programAmount: {
    ar: "حجم البرنامج",
    en: "Program Amount",
  },
  timeUp: {
    ar: "دخل وقت الفرصة",
    en: "The time of opportunity entered",
  },
  opportunityDetails: {
    ar: "تفاصيل الفرصة",
    en: "Details",
  },
  listOpportunitis: {
    ar: "عرض كل الفرص الاستثمارية",
    en: "List all opportunities",
  },
  apr: {
    ar: "العائد السنوي (APR)",
    en: "APR",
  },
  duration: {
    ar: "الفترة",
    en: "Duration",
  },
  invest: {
    ar: "استثمر الآن",
    en: "Invest Now",
  },
  issuanceNumber: {
    ar: "رقم الاصدار",
    en: "Issuance Number",
  },
  availableOpportunity: {
    ar: "فرصة متاحة",
    en: "AVAILABLE",
  },
  closedOpportunity: {
    ar: "فرصة مغلقة",
    en: "CLOSED",
  },
  comingOpportunity: {
    ar: "فرصة قادمة",
    en: "COMING",
  },
  coverage: {
    ar: "نسبة التغطية",
    en: "Coverage ratio",
  },
  starting: {
    ar: "ستبدأ الفرصة في",
    en: "Opportunity will start in",
  },
  days: {
    ar: "أيام",
    en: "Days",
  },
};

export const currency = {
  ar: "ريال",
  en: "Riyal",
};

export const faqLang = {
  listFaq: {
    ar: "عرض كل الاسئلة",
    en: "List all the questions",
  },
};

export const login = {
  ar: "تسجيل الدخول",
  en: "Login",
};

export const sign = {
  ar: "تسجيل جديد",
  en: "Sign Up",
};

